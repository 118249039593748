import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import sendEmail from '../../../service/sendEmail'
import './BecomeTutorModal.css'

function BecomeTutorModal({formData, confirm, setConfirm, resetForm, setInquiryClicked}) {
  const [inquiryComplete, setInquiryComplete] = useState(false)
  const { 
    firstName, 
    lastName,
    gradeLevel, 
    email,
    phone,
    message
   } = { ...formData }

  const inquire = async () => {
    const formattedData = { ...formData }

    const res = await sendEmail(formattedData, 'api/email/becometutor')

    if(res.status === 200) {
      setInquiryComplete(true)
      setInquiryClicked(false)
      resetForm()
    } else {
      console.error(res)
    }
  }
  return (
    <>
    { confirm && 
      <div className="schedule-modal-shade">
        <div className="schedule-modal-container">
          <div className="modal fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto">
            <div className="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
              <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                  <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                    Confirm Your Information
                  </h5>
                  <button onClick={(e) => setConfirm(false)} type="button"
                    className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="py-2 px-7">
                  { inquiryComplete === false ?
                    <>
                      <p className="text-sm my-3 mx-0">Thank you for your interest in becoming a tutor! Before submitting your tutoring inquiry.
                        Please confirm the information below is correct</p>
                      <div className="leading-8 sm:leading-10 text-sm sm:text-base">
                        <strong>Name:</strong> {firstName} {lastName}<br/>
                        <strong>Grade Level:</strong> {gradeLevel} <br/>
                        <strong>Email:</strong> {email}<br/>
                        <strong>Phone:</strong> {phone}<br/>
                        <strong>Message:</strong> <p className="text-sm m-0 p-2 bg-gray-100">{message ? message : <i>Blank</i> }</p>
                      </div>
                    </>
                    :
                      <>
                        <h2>Thanks again for your interest in becoming a tutor!</h2>
                        <p className="text-sm my-3 mx-0">Please check your email for confirmation.</p>
                      </>
                  }
                </div>
                <div
                  className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                  { inquiryComplete === false ?
                      <>
                        <button onClick={(e) => setConfirm(false)} type="button"
                          className="inline-block px-6 py-2.5 bg-orange-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                          data-bs-dismiss="modal">
                          Edit
                        </button>
                        <button onClick={() => inquire()}type="button"
                          className="pointer inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                          Submit
                        </button>
                      </>
                    :
                      <Link to="/" className="pointer inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">Home</Link>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  )
}

export default BecomeTutorModal
