import React from 'react'
import './ContentHeading.css'

const ContentHeading = ({text}) => {
  return (
    <div className="content-heading">
      <h1>{text}</h1>
    </div>
  )
}

export default ContentHeading
