import { useEffect, useMemo } from 'react'
import StripeCheckout from '../../../service/StripeCheckout'

const CheckoutCancelView = () => {
  const storageKey = (new StripeCheckout()).storageKey
  const checkoutData = useMemo(() => {
    return JSON.parse(localStorage.getItem(storageKey)) ?? {}
  }, [storageKey])

  useEffect(() => {
    if(Object.keys(checkoutData).length === 0) {
        window.location = window.location.origin
      } else {
        let pathName = '/'
        if(checkoutData?.type === 'regular') {
          pathName ='/tutoring'
        } else if(checkoutData?.type === 'subscription') {
          pathName ='/membership'
        }

        window.location.pathname = pathName
      }
  }, [checkoutData])

}

export default CheckoutCancelView
