import { useEffect, useMemo } from 'react'
import sendEmail from '../../../service/sendEmail'
import StripeCheckout from '../../../service/StripeCheckout'
import './CheckoutSuccessView.css'

const CheckoutSuccessView = () => {
  const storageKey = (new StripeCheckout()).storageKey
  const checkoutData = useMemo(() => {
    return JSON.parse(localStorage.getItem(storageKey)) ?? {}
  }, [storageKey])

  useEffect(() => {
      if(Object.keys(checkoutData).length === 0) {
        window.location = window.location.origin
      } else {
        try {
          const sendPurchaseMails = async () => {
            await sendEmail(checkoutData, 'api/email/purchase')
          }
          sendPurchaseMails()
          localStorage.removeItem(storageKey)
        } catch (err) {
          console.error(err)
        }
      }
  }, [checkoutData, storageKey])
  
  return (
    <div className="checkout-success-view">
      <h3>Thank you for your {checkoutData?.type === 'subscription' ? 'Membership' : 'Tutoring'} Purchase!</h3>
      <p>We will get back to you as soon as possible!</p>
      <div>
        <strong>Name:</strong> {checkoutData?.customerInfo?.firstName} {checkoutData?.customerInfo?.lastName}<br/>
        <strong>E-Mail:</strong> {checkoutData?.customerInfo?.email}<br/>
        <strong>Phone:</strong> {checkoutData?.customerInfo?.phone}<br/>
      </div>
    </div>
  )
}

export default CheckoutSuccessView
