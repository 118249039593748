import { CardOuter } from '../../uikit/Containers'
import CardPrice from './CardPrice'
import Quantity from '../../uikit/Forms/Quantity'
import './ServiceBuyCard.css'

const ServiceBuyCard = ({ productInfo, quantityState }) => {
  const { name, description, price } = productInfo
  const { quantity, quantitySetter } = quantityState

  const getTextFromHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent ?? '';
  }
  return (
    <CardOuter>
      <div className="service-buy-card">
        <h3 className="service-buy-card__title">{name}</h3>
        <p className="service-buy-card__description">{getTextFromHtml(description)}</p>
        <CardPrice priceInCents={price} unitText="each"/>
        <Quantity amount={quantity} setter={quantitySetter}/>
      </div>
    </CardOuter>
  )
}

export default ServiceBuyCard
