import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import ContentHeading from '../../uikit/ContentHeading/ContentHeading'
import validate from '../../../utility/validate'
import format from '../../../utility/format'
import BecomeTutorModal from '../../block/BecomeTutorModal/BecomeTutorModal'
import './BecomeTutorView.css'


const BecomeTutorView = () => {
  const [invalidFields, setInvalidFields] = useState([])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [gradeLevel, setGradeLevel] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [inquiryClicked, setInquiryClicked] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const handlePhone = (e) => {
    setPhone(format.phone(e.target.value));
  }

  const getFormStateData = () => {
    return {
      firstName,
      lastName,
      gradeLevel,
      email,
      phone,
      message
    }
  }

  const validateBecomeTutor = async (e) => {
    e.preventDefault()
    setInquiryClicked(true)

    let invalidFieldsChecked = []
    if (firstName === '' ||
      lastName === '' ||
      gradeLevel === ''
    ) {
      invalidFieldsChecked.push('Incomplete fields are highlighted in red')
    }

    if (!validate.email(email)) {
      invalidFieldsChecked.push("Email - must be a valid email format")
    }

    if (phone.length < 14) {
      invalidFieldsChecked.push("Phone Number - must be a full phone number")
    }

    if (invalidFieldsChecked.length > 0) {
      setInvalidFields(invalidFieldsChecked);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return false
    }

    setInvalidFields([]);
    setConfirm(true)
    return true
  }

  const resetForm = () => {
    setFirstName('')
    setLastName('')
    setGradeLevel('')
    setEmail('')
    setPhone('')
    setMessage('')
  }

  return (
    <div>
      <BecomeTutorModal
        formData={getFormStateData()}
        confirm={confirm}
        setConfirm={setConfirm}
        resetForm={resetForm}
        setInquiryClicked={setInquiryClicked}
      />
      <ContentHeading text="Become a Tutor" />
      <p className="text-center">Tell Max how you would like to become a Tutor</p>
      <form onSubmit={(e) => validateBecomeTutor(e)} id="schedule-form">
        <div className="text-left relative p-4">
          {invalidFields.length > 0 &&
            <div className="invalid-fields">
              <h4 className="font-bold">Please check the fields below</h4>
              <ul>
                {invalidFields.map((field, key) => {
                  return <li key={key}>{field}</li>
                })}
              </ul>
            </div>
          }
          <div className="form-control">
            <div className="form-row sm:flex">
              <div className="input-container sm:w-6/12">
                <TextField
                  required
                  error={firstName === '' && inquiryClicked}
                  id="firstname-required"
                  label="First Name"
                  className="w-full"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="input-container sm:w-6/12">
                <TextField
                  required
                  error={lastName === '' && inquiryClicked}
                  id="lastname-required"
                  label="Last Name"
                  className="w-full"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-control">
            <div className="form-row sm:flex">
              <div className="input-container">
                <FormControl sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id="session-length-label">Grade Level</InputLabel>
                  <Select
                    required
                    error={gradeLevel === '' && inquiryClicked}
                    labelId="session-duration-label"
                    id="session-length"
                    value={gradeLevel}
                    label="Session Length"
                    onChange={(e) => setGradeLevel(e.target.value)}
                  >
                    <MenuItem disabled selected value="">
                      <em>Grade Level</em>
                    </MenuItem>
                    <MenuItem value="Kindergarten">Kindergarten</MenuItem>
                    <MenuItem value="First Grade">1st Grade</MenuItem>
                    <MenuItem value="Second Grade">Second Grade</MenuItem>
                    <MenuItem value="Third Grade">Third Grade</MenuItem>
                    <MenuItem value="Fourth Grade">Fourth Grade</MenuItem>
                  </Select>
                  <FormHelperText>Select a session length</FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="form-control">
            <div className="form-row sm:flex">
              <div className="input-container sm:w-6/12">
                <TextField
                  required
                  error={(email === '' && inquiryClicked) || (!emailValid && inquiryClicked)}
                  id="email-required"
                  label="Email Address"
                  className="w-full"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailValid(validate.email(email))
                  }}
                />
              </div>
              <div className="input-container sm:w-6/12">
                <TextField
                  required
                  error={(phone === '' && inquiryClicked) || ((phone.length < 14) && inquiryClicked)}
                  id="phone-required"
                  label="Phone Number"
                  className="w-full"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => handlePhone(e)}
                />
              </div>
            </div>
          </div>
          <div className="form-control">
            <div className="input-container px-5">
              <InputLabel id="message-label">Your Message</InputLabel>
              <TextareaAutosize
                className="border border-gray-300 w-full p-2"
                maxRows={5}
                minRows={5}
                aria-label="Your message"
                placeholder="Write your message here."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </div>
      </form>
      <div
        className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
        <div className="">
          <button onClick={(e) => validateBecomeTutor(e)} type="button" className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
            Send Message
          </button>
        </div>
      </div>
    </div>
  )
}

export default BecomeTutorView
