import './ButtonPrimary.css'

const ButtonPrimary = ({onClick, text, className, disabled = ''}) => {
  return (
    <button onClick={onClick} type="button" className={className !== undefined ? className : 'button-primary' } disabled={disabled}>
      {text}
    </button>
  )
}

export default ButtonPrimary
