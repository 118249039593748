import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Footer from './component/block/Footer/Footer'
import Header from './component/block/Header/Header'
import HomeView from './component/view/Home/HomeView'
import ServicesView from './component/view/Services/ServicesView'
import TutoringView from './component/view/Tutoring/TutoringView'
import MembershipView from './component/view/Membership/MembershipView'
import BecomeTutorView from './component/view/BecomeTutor/BecomeTutorView'
import CheckoutCancelView from './component/view/Checkout/CheckoutCancelView'
import CheckoutSuccessView from './component/view/Checkout/CheckoutSuccessView'
import './App.css'
import BookNowView from './component/view/BookNow/BookNowView'
import { ContainerInner, ContainerOuter, SectionWrapper } from './component/uikit/Containers'

function App() {
  return (
    <div className="layout-container">
      <BrowserRouter>
        <Header />
        <ContainerOuter>
          <ContainerInner>
            <SectionWrapper>
            <Routes>
              <Route path="/" element={<HomeView/>}/>
              <Route path="/services" element={<ServicesView/>}/>
              <Route path="/membership" element={<MembershipView/>}/>
              <Route path="/tutoring" element={<TutoringView/>}/>
              <Route path="/becomeatutor" element={<BecomeTutorView/>}/>
              <Route path="/checkout/cancel" element={<CheckoutCancelView/>}/>
              <Route path="/checkout/success" element={<CheckoutSuccessView/>}/>
              <Route path="/booknow" element={<BookNowView/>}/>
            </Routes>
          </SectionWrapper>
          </ContainerInner>
        </ContainerOuter>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
