import './FormControlRow.css'

const FormControlRow = ({children}) => {
  return (
    <div className="form-control">
      <div className="form-row sm:flex">{children}</div>
    </div>
  )
}

export default FormControlRow
