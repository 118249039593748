import './FormFooter.css'

const FormFooter = ({ children }) => {
  return (
    <div className="form-footer">
      {children}
    </div>
  )
}

export default FormFooter
