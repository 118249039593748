const format = {
  /**
   * 
   * @param number | Date | undefined date 
   * @returns String Takes the numeric data and returns something like Saturday, Jul 9, 2022 4:00 PM
   */
  date: (date) => {
    const dateFormatted = Intl.DateTimeFormat(navigator.language, {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(date)
    
    let hour = (date.getHours() > 12 ?  date.getHours() - 12 : date.getHours())
    if(hour < 1) {
      hour = 12
    }
    let minutes = date.getMinutes();
    if(minutes < 10) {
      minutes = `0${minutes}`
    }
    let ampm = (date.getHours() > 12 ? 'PM' : 'AM')

    return `${dateFormatted} ${hour}:${minutes} ${ampm}`
  },

  /**
   * @param String phone 
   * @returns String A formatted as (123) 456-7890
   */
  phone: (phone) => {
    let x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  },

  /**
   * @param number cents 
   * @returns String Cents converted to dollars with a leading dollar sign
   */
  price: (cents) => {
    const dollars = cents / 100
    return `$${dollars}` 
  }
}

export default format
