import './InputContainer.css'

const InputContainer = ({ classMod, children }) => {
  const defaultClass = 'input-container'
  const compiledClass = () => {
    return classMod ?`${defaultClass} ${classMod}` : defaultClass
  }
  return (
    <div className={compiledClass()}>{children}</div>
  )
}

export default InputContainer
