import React from 'react'
import getImage from '../../../utility/getImage'
import './HomeHero.css'

const HomeHero = () => {
  return (
    <div className="container">
      <section className="mb-0 lg:mb-5 xl:mb-7">
        <div className="flex md:flex-wrap">
          <div className="grow-0 shrink-0 basis-auto mt-5 lg:w-4/12 w-4/12 md:w-4/12 mb-5 lg:mb-0">
            <div className="flex lg:py-12">
              <img src={getImage('max-headshot-example.jpg')} className="w-full rounded-md shadow-lg lg:z-10"
                id="cta-img-nml-50" alt="" />
            </div>
          </div>

          <div className="lg:grow-0 basis-auto w-auto lg:w-8/12 md:w-8/12">
            <div className="bg-orange-500 h-full rounded-md p-2 lg:p-6 lg:pl-12 text-white flex items-center text-center lg:text-left">
              <div className="lg:pl-12">
                <h2 className="hero__title">Greetings!</h2>
                <p className="md:text-sm first-letter lg:text-base text-xs mt-1 b-0 lg:mb-6 pb-1 lg:pb-0">
                  Hi, my name is Max Levin and I am a Math Wiz Max!!!  I am 9 years old and used to struggle with math but now I think it is cool! I am a KUMON champion, having been attending the program since I was 4 years old and have excelled at the advanced math, algebra and geometry by being part of the prestigious Russian School of Mathematics for the past 5 years. I can teach you how to make math easy and fun, while discovering new strategies to approach math problems!  Book a one-on-one Zoom session with me or join me and my friends in a daily interactive Zoom group in a “Homework Together”!  Grades K-4th grades welcome!  Let's get smarter together!
                </p>

                <div className="hidden xl:flex flex-col lg:flex-row lg:justify-around xl:justify-center mb-6 mx-auto">
                  <p className="flex items-center mb-4 lg:mb-2 lg:mb-0 mx-auto lg:mx-0 xl:mr-20">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-2">
                      <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                      </path>
                    </svg>
                    Homework
                  </p>

                  <p className="flex items-center mb-4 lg:mb-2 lg:mb-0 mx-auto lg:mx-0 xl:mr-20">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-2">
                      <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                      </path>
                    </svg>
                    Peer-To-Peer Tutoring
                  </p>

                  <p className="flex items-center mb-2 lg:mb-0 mx-auto lg:mx-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-2">
                      <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                      </path>
                    </svg>
                    Testing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomeHero
