import axios from 'axios'
import getEncryptKey from './getEncryptKey'

export default async function sendEmail(emailData, uri) {
  const sendData = {
    tutoring_session_key: getEncryptKey(),
    ...emailData
  }

  const sendUrl = `${process.env?.REACT_APP_ENV === 'local' ? process.env.REACT_APP_EMAIL_SEND_BASE_URL : ''}/${uri}`
  
  return await axios.post(sendUrl, sendData)
    .then(res => { 
      return res 
    })
    .catch(err => {
      console.error(err)
    })
}
