import format from '../../../utility/format'
import './CardPrice.css'

const CardPrice = ({ priceInCents, unitText}) => {
  return (
    <div className="card-price">
      <h3 className="card-price__value">
        <strong>{format.price(priceInCents)}</strong>
        <small className="card-price__unit">/{unitText}</small>
      </h3>
    </div>
  )
}

export default CardPrice
