import axios from 'axios'
export default class StripeCheckout {
  
  /**
   * Payment session URI
   * 
   * @var string
   */
  paymentSessionUri = '/api/payment/session'

  /**
   * THe key used for localStorage
   * 
   * @var string
   */
  storageKey = 'stripe-checkout'

  /**
   * Data
   * 
   * @var {*} data Information about the customer, and checkout.
   */
  data = {
    type: 'standard', // Type of checkout, `subscription` or `standard`
    customerInfo: {}, // Usually the info entered by the customer.
    items:[] // Each element should be objects.
  }

  /**
   * Constructor
   * 
   * @param {*} data Information about the customer, and checkout.
   */
  constructor(data = {}) {
    this.data = data
  }

  /**
   * @returns string this.data.type The type of checkout,
   */
  getType() {
    return this.data?.type ?? ''
  }

  /**
   * Get Customer INfo
   * 
   * @returns {} The customerInfo from the data
   */
  getCustomerInfo() {
    return this.data.customerInfo
  }

  /**
   * Get the data to be stored in localstorage
   * 
   * @returns string The data outpust as a string
   */
  getDataForLocalStorage() {
    return JSON.stringify(this.data)
  }

  /**
   * Set the storage key
   * 
   * @params string The key to be used for local storage
   */
  setStorageKey(key) {
    this.storageKey = key
  }

  /**
   * Save the data to localStorage
   */
  saveToLocalStorage() {
    localStorage.setItem(this.storageKey, this.getDataForLocalStorage())
  }

  /**
   * Request a payment session, and return the and go to stripe.
   * 
   * @return {string} paymentUri the url to go to for checkout
   */
  async requestPaymentSession() {
    const baseUrl = process.env.REACT_APP_PAYMENT_SEND_BASE_URL
    console.log(`${baseUrl}${this.paymentSessionUri}`)
    let paymentUri = ''
    
    await axios.post(`${baseUrl}${this.paymentSessionUri}`, this.data)
      .then(res => {
        paymentUri = res.data.url
      })
      .catch(err => {
        console.error(err)
      })

    return paymentUri 
  }
}
