import ContentHeading from '../../uikit/ContentHeading/ContentHeading'
import ServiceCards from '../../block/ServiceCards/ServiceCards'

 const ServicesView = () => {
  return (
    <div className="services-view">
      <ContentHeading text="Services"/>
      <ServiceCards/>
    </div>
  )
 }

 export default ServicesView
