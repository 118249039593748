import React from 'react'
import NavTop from '../NavTop/NavTop'
import Trademark from '../../uikit/Symbols/Trademark'
import './Header.css'

function Header() {
  return (
    <div className="header">
      <div className="flex">
        <h1 className="header__title">MathWizMax</h1><Trademark/>
      </div>
      <NavTop />
    </div>
  )
}

export default Header
