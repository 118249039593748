import React, { useEffect, useState } from 'react'
import CheckMarkIcon from '../../uikit/Svg/CheckMarkIcon';
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import ContentHeading from '../../uikit/ContentHeading/ContentHeading'
import format from '../../../utility/format'
import validate from '../../../utility/validate'
import { CardOuter } from '../../uikit/Containers'
import { ButtonPrimary, FormControlRow, FormFooter, InputContainer, InvalidFields } from '../../uikit/Forms'
import { Spinner } from '../../uikit/Overlay'
import StripeCheckout from '../../../service/StripeCheckout'
import './MembershipView.css';

const MembershipView = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    gradeLevel: '',
    email: '',
    phone: '',
    confirm: false
  })

  const [validation, setValidation] = useState({
    invalidFields: [],
    attemptedSubmit: false,
  })

  useEffect(() => {
    const storedCustomerData = JSON.parse(localStorage.getItem((new StripeCheckout()).storageKey))
    if(typeof storedCustomerData === 'object' && storedCustomerData !== null) {
      const stripeCheckout = new StripeCheckout(storedCustomerData)
      setFormState(stripeCheckout.getCustomerInfo());
    }
  }, [])

  const setFieldState = (target) => {
    const { name , value } = target

    setFormState({
      ...formState,
      [name]: value
    })
  }

  const handlePhone = (target) => {
    const phoneUpdate = {
      name: target.name,
      value: format.phone(target.value)
    }

    setFieldState(phoneUpdate);
  }

  const validateSubscription = async () => {
    
    prepareValidationState()

    const invalidFieldsChecked = checkInvalidFields()
    
    setValidation({
      ...validation,
      invalidFields: invalidFieldsChecked,
      attemptedSubmit: true
    })

    if(invalidFieldsChecked.length > 0) {
      window.scroll({top: 0, left: 0, behavior: 'smooth'})
      return false
    }
    
    try {
      const checkoutData = {
        type: 'subscription',
        customerInfo: formState,
        items: [{ id: 3, quantity: 1}]
      }

      const stripeCheckout = new StripeCheckout(checkoutData)
      stripeCheckout.saveToLocalStorage()
      setIsLoading(true)
      window.location = await stripeCheckout.requestPaymentSession()
      return true
    } catch (err) {
      console.error(err)
      return false
    }

    
  }

  const prepareValidationState = () => {
    setFormState({
      ...formState,
      firstName: document.querySelector('input[name="firstName"]').value,
      lastName: document.querySelector('input[name="lastName"]').value,
      email: document.querySelector('input[name="email"]').value,
      phone: document.querySelector('input[name="phone"]').value
    })
  }

  const checkInvalidFields = () => {
    const {
      firstName,
      lastName,
      gradeLevel,
      email,
      phone,
      confirm
    } = formState

    let invalidFieldsChecked = []

    if (firstName === '' ||
      lastName === '' ||
      gradeLevel === ''
    ) {
      invalidFieldsChecked.push('Incomplete fields are highlighted in red')
    }

    if (!validate.email(email)) {
      invalidFieldsChecked.push("Email - must be a valid email format")
    }

    if (phone.length < 14) {
      invalidFieldsChecked.push("Phone Number - must be a full phone number")
    }

    if (confirm === false) {
      invalidFieldsChecked.push("Check the confirmation box after reviewing your information is correct.")
    }

    return invalidFieldsChecked
  }

  return (
    <section className="membership-view__section">
      { isLoading ? <Spinner/> : <></>}
      <ContentHeading text="Monthly Membership"/>
      <div className="">
        <div className="membership-view__card-container">
          <CardOuter>
            <div className="card-container__inner">
              <p className="membership-view__subtitle">
                <strong>Enjoy The Benefits</strong>
              </p>
              <h3 className="text-2xl mb-6">
                <strong>$ 130</strong>
                <small className="text-gray-500 text-sm">/month</small>
                <p className="text-base">For a monthly membership fee of $130, receive individual access to Max with ad hoc math problems and Q&amp;A, up to 2 Individual Zoom sessions and the ability to join up to 3 “Homework Together” group sessions per month.</p>
              </h3>
            </div>
            <div className="p-6">
              <ol className="list-inside">
                <li className="subscription-benefit">
                  <CheckMarkIcon className="check-mark"/>On-demand Zoom sessions
                </li>
                <li className="subscription-benefit">
                  <CheckMarkIcon className="check-mark"/>Join any daily lesson
                </li>
                <li className="subscription-benefit">
                  <CheckMarkIcon className="check-mark"/>Ad hoc questions answered all school year
                </li>
              </ol>
              <div className="signup-view">
                <div className="signup-view__inner">
                  <div className="signup-form">
                    <form id="membership-form">
                      <div className="text-left relative p-4">
                        <InvalidFields invalidFields={validation.invalidFields}/>
                        <FormControlRow>
                          <InputContainer classMod="sm:w-6/12">
                            <TextField
                              required
                              error={formState.firstName === '' && validation.attemptedSubmit}
                              id="firstname-required"
                              label="First Name"
                              className="w-full"
                              placeholder="First Name"
                              name="firstName"
                              value={formState.firstName}
                              onChange={(e) => setFieldState(e.target)}
                            />
                          </InputContainer>
                          <InputContainer classMod="sm:w-6/12">
                            <TextField
                              required
                              error={formState.lastName === '' && validation.attemptedSubmit}
                              id="lastname-required"
                              label="Last Name"
                              className="w-full"
                              placeholder="Last Name"
                              name="lastName"
                              value={formState.lastName}
                              onChange={(e) => setFieldState(e.target)}
                            />
                          </InputContainer>
                        </FormControlRow>
                        <FormControlRow>
                          <InputContainer>
                            <FormControl sx={{ minWidth: 300 }}>
                            <InputLabel id="session-length-label">Grade Level</InputLabel>
                              <Select
                                required
                                error={formState.gradeLevel === '' && validation.attemptedSubmit}
                                labelId="session-duration-label"
                                id="session-length"
                                name="gradeLevel"
                                value={formState.gradeLevel}
                                label="Session Length"
                                onChange={(e) => setFieldState(e.target)}
                              >
                              <MenuItem disabled selected value="">
                                <em>Grade Level</em>
                              </MenuItem>
                              <MenuItem value="Kindergarten">Kindergarten</MenuItem>
                              <MenuItem value="First Grade">1st Grade</MenuItem>
                              <MenuItem value="Second Grade">Second Grade</MenuItem>
                              <MenuItem value="Third Grade">Third Grade</MenuItem>
                              <MenuItem value="Fourth Grade">Fourth Grade</MenuItem>
                            </Select>
                            <FormHelperText>Select a grade level</FormHelperText>
                          </FormControl>
                          </InputContainer>
                        </FormControlRow>
                        <FormControlRow>
                          <InputContainer classMod="sm:w-6/12">
                            <TextField
                              required
                              error={(formState.email === '' && validation.attemptedSubmit) || (!validate.email(formState.email) && validation.attemptedSubmit)}
                              id="email-required"
                              label="Email Address"
                              className="w-full"
                              placeholder="Email"
                              name="email"
                              value={formState.email}
                              onChange={(e) => {
                                setFieldState(e.target)
                              }}
                            />
                          </InputContainer>
                          <InputContainer classMod="sm:w-6/12">
                            <TextField
                              required
                              error={(formState.phone === '' && validation.attemptedSubmit) || ((formState.phone.length < 14) && validation.attemptedSubmit)}
                              id="phone-required"
                              label="Phone Number"
                              className="w-full"
                              placeholder="Phone Number"
                              name="phone"
                              value={formState.phone}
                              onChange={(e) => handlePhone(e.target)}
                            />
                          </InputContainer>
                        </FormControlRow>
                        <FormControlRow>
                        <InputContainer>
                          <FormGroup className={!formState.confirm && validation.attemptedSubmit ? 'text-red-600' : '' }>
                            <FormControlLabel control={<Checkbox name="confirm" checked={formState.confirm} onChange={(e) => setFormState({ ...formState, [e.target.name]: e.target.checked })} />} label="I confirm that the entered information is correct." />
                          </FormGroup>
                        </InputContainer>
                      </FormControlRow>
                        </div>
                        <FormFooter>
                          <ButtonPrimary className="membership-view__signup-button" onClick={() => validateSubscription()} text="Sign Up Now" disabled={isLoading ? 'disabled' : ''}/>
                        </FormFooter>
                      </form>
                  </div>
                </div>
              </div>
            </div>
          </CardOuter>
        </div>
      </div>
    </section>
  )
}

export default MembershipView
