import { Link } from 'react-router-dom'
import './ButtonLink.css'

const ButtonLink = ({linkTo, text, className}) => {
  return (
    <Link to={linkTo} className={className !== undefined ? className : 'button-link' }>
      {text}
    </Link>
  )
}

export default ButtonLink
