import React from 'react'
import HomeHero from '../../block/HomeHero/HomeHero'
import ServiceCards from '../../block/ServiceCards/ServiceCards'

const HomeView = () => {
  return (
    <div className="home-view">
      <HomeHero/>   
      <div>
        <h3 className="section__heading">Services</h3>
      </div>
      <div className="home-view__cards-outer">
        <div className="cards-outer__cards-container">
          <ServiceCards/>
        </div>
      </div>
    </div>
  )
}

export default HomeView
