import { Link } from 'react-router-dom'
import { ButtonLink } from '../../uikit/Forms'
import getImage from '../../../utility/getImage'
import './ServiceCards.css'

const ServiceCards = () => { 
  return (
    <div className="cards-outer__cards-container">
      <div className="cards-container__card">
        <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
          <img className="card__image" src={getImage('plus-signs.jpg')} alt=""/>
        </a>
        <div className="card__inner">
          <div className="card_inner-spacer">
            <h5 className="card__heading">Individual Zoom Session</h5>
            <p>
              Work on an individual basis with Max or one of our exceptional tutors on homework, test prep or pre-submitted math questions and / or word problems.<br/><br/>
            </p>
            <p>10am - 12pm and 4-6pm</p>
            <p>$30 for a 30-minute individual zoom session. <Link to="/tutoring" className="text-blue-600">Book now!</Link></p>
          </div>
          <ButtonLink linkTo="/tutoring" text="Book Now"/>
        </div>
      </div>
      <div className="cards-container__card">
        <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
          <img className="card__image" src={getImage('calculator-and-paper.jpg')} alt=""/>
        </a>
        <div className="card__inner">
          <div className="card_inner-spacer">
            <h5 className="card__heading">Homework Together</h5>
            <p>
              Complete your assigned schoolwork along with your classmates in this interactive daily peer-to-peer tutoring group Zoom session called “Homework Together”.  Moderated by Max or one of our exceptional tutors. 
            </p>
            <p>Daily, Monday - Thursday, 6:30-7:30pm</p>
            <p>$20 (per session) daily Zoom group</p>
          </div>
          <ButtonLink linkTo="/tutoring" text="Book Now"/>
        </div>
      </div>
      <div className="cards-container__card">
        <a href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">
          <img className="card__image" src={getImage('child-chalkboard.jpg')} alt=""/>
        </a>
        <div className="card__inner">
          <div className="card_inner-spacer">
            <h5 className="card__heading">Membership</h5>
            <p className="card__card-content">
              For a monthly meambership fee of $130, receive individual access to Max with ad hoc math problems and Q&amp;A, up to 2 Individual Zoom sessions and the ability to join up to 3 “Homework Together” group sessions per month.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <br/>
          </div>
          <ButtonLink linkTo="/membership" text="Join Now"/>
        </div>
      </div>
    </div>
  )
}

export default ServiceCards
