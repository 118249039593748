import React from 'react'
import { Link } from 'react-router-dom'
import './NavTop.css'

const NavTop = () => {

  const unCheckToggle = () => {
    document.getElementById('nav-top__toggle').attribute('checked', false)
  }
  const navItems = [
    {
      to: "/",
      text: "Home"
    },
    {
      to: "/services",
      text: "Services"
    },
    {
      to: "/membership",
      text: "Membership"
    },
    {
      to: "/becomeatutor",
      text: "Become A Tutor"
    },
    {
      to: "/tutoring",
      text: "Book Now"
    }
  ]

  const navLinkClass = 'nav-top__item';
  return (
    <nav className="nav-top navbar">
      <div className="nav-top__desktop">
        {navItems.map((navItem, key) => <Link key={key} to={navItem.to} className={navLinkClass}>{navItem.text}</Link>)}
      </div>
      <div className="nav-top__mobile">
        <input type="checkbox" className="nav-top__toggle" name="nav-top__toggle" id="nav-top__toggle" />
        <label className="nav-top__hamburger" htmlFor="nav-top__toggle">
          <span className="hamburger__line"></span>
          <span className="hamburger__line"></span>
          <span className="hamburger__line"></span>
          <span className="hamburger__line"></span>
          <span className="hamburger__line"></span>
        </label>
        <div className="mobile__links">
          {navItems.map((navItem, key) => <Link key={key} onClick={() => unCheckToggle()} to={navItem.to} className={navLinkClass}>{navItem.text}</Link>)}
        </div>
      </div>
    </nav>
  )
}

export default NavTop
