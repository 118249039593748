import './InvalidFields.css'

const InvalidFields = ({invalidFields}) => {
  return (
    <>
    {invalidFields.length > 0 &&
      <div className="invalid-fields">
        <h4 className="font-bold">Please check the fields below</h4>
        <ul>
        {invalidFields.map((field, key) => {
          return <li key={key}>{field}</li>
        })}
        </ul>
      </div>
    }
    </>
  )
}

export default InvalidFields
