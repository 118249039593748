import { useRef } from 'react'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import './Quantity.css'

const Quantity = ({ amount, setter}) => {
  const amountRef = useRef(amount)
  const decrease = () => {
    if (amountRef.current.value > 0 ) {
      setter(--amountRef.current.value)
    }
  }

  const increase = () => {
    setter(++amountRef.current.value)
  }

  return(
    <div className="quantity-container">
      <button className={`quanity decrease ${amount < 1  ? 'disabled' : ''}`} onClick={() => decrease()} type="button"><Remove/></button>
      <input className="quantity-input" type="number" ref={amountRef} defaultValue={amount}/>
      <button className="quanity increase" onClick={() => increase()} type="button"><Add/></button>
    </div>
  )
}

export default Quantity
